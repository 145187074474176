
@media (min-width: 1200px){
.container {
    max-width: 1440px;
}
}

@media (max-width: 1199.98px) {.box01 .btns img{max-width: 120px;}}

@media (max-width: 1024.98px) {
 .bannertext h1{    font-size: 45px;
    }
    .bannertext h1 span{font-size: 38px;}
    .bannertext p{font-size: 16px;}
    
    .bannertext .btns a img{max-width: 130px;}
    .titleinner h2{font-size: 30px;}

}
@media (max-width: 991.98px) { 
    .bannertext h1{    font-size: 45px;
    }
    .bannertext h1 span{font-size: 38px;}
    .bannertext p{font-size: 16px;}
    .navigation{padding: 0 0px; background: #fff;}
    .navigation .navbar-nav{align-items: flex-start; padding-top: 20px;}
    .navigation .navbar-nav li{display: flex; width: 100%; flex-flow: column;}
    .navigation .navbar-nav li a{width: 100%;}
    .navigation .navbar-nav li.login a{text-align: center;}
    .navigation .navbar-nav .dropdown-menu{box-shadow: none;}
    
    .learnmoreabout ul li a{    padding: 10px 21px;    font-size: 13px;}
    .carebox .iconbox{    width: 100px;    height: 100px;}
    .carebox h3{font-size: 18px;}
}

@media (max-width: 767.98px) {.learnmoreabout ul{flex-flow: wrap;}
    .learnmoreabout ul li{width: 50%; padding: 10px 0;} 
.outcometitle{text-align: center;}
    .banner_sec{    padding: 8rem 0px 20px 0px;}
    .bannertext .btns{padding: 0 0 25px 0}
    .ourcompanylogos{flex-flow: wrap; justify-content: center;}
    .ourcompanylogos li a{width: 120px;}

}




@media (max-width: 640px) {  }

@media (max-width: 575.98px) {
    .learnmoreabout ul li a{font-size: 14px; padding: 10px 15px;}
    .outcometitle{font-size: 26px;}
    
    .navigation .navbar{padding: 8px 0;}
}