

html {
  font-family:  sans-serif;
  font-size: 14px;
}
body {
  background-color: #ffffff;
  font-family:  sans-serif;
  font-size: 1.14rem;
  font-weight: 300;
  /* color: #b0b0b0; */
  line-height: normal;
  position: relative;
}

::-webkit-scrollbar {
  width: 8px;
  height: 8px;
  box-shadow: inset 0px 0px 6px rgba(0, 0, 0, 0.3);
  border-radius: 8px;
}
/*add a shadow to the scrollbar here*/
::-webkit-scrollbar-track {
  box-shadow: inset 0px 0px 0px rgba(0, 0, 0, 0.3);
  border-radius: 8px;
}
Â
/*this is the little scrolly dealio in the bar*/
::-webkit-scrollbar-thumb {
  border-radius: 8px;
  background-color: rgba(0, 0, 0, 0.25);
  height: 3px;
}
/*nobody needs this little scrollbar corner, I mean really, get rid of it haha*/
::-webkit-scrollbar-corner {
  display: none;
  height: 0px;
  width: 0px;
}

img {
  max-width: 100%;
  display: inline-block;
}
button,
input,
textarea,
select {
  font-family:  sans-serif;
  opacity: 1;
  letter-spacing: 0.0143rem;
}
button:focus,
input:focus,
textarea:focus,
select:focus {
  outline: none;
}
ul,
li {
  padding: 0px;
  margin: 0px;
  list-style: none;
}
a {
  display: inline-block;
  -webkit-transition: all 0.5s ease-out;
  transition: all 0.5s ease-out;
  color: #201b4c;
  text-decoration: none;
}
a:hover,
a:focus {
  outline: inherit;
  text-decoration: none;
}
a:hover {
  color: #201b4c;
}
Â h1,
h2,
h3,
h4,
h5,
h6 {
  font-family:  sans-serif;
  line-height: normal;
  padding: 0px 0px;
  margin: 0px 0px;
}

.navigation {
  width: 100%;
  position: absolute;
  top: 0%;

  z-index: 9;
  padding: 0 40px;
}

.navigation.fixed-header {
  background: #ffffff;
  border-bottom: 1px solid #8ebac6;
  box-shadow: 10px 5px 10px rgb(10 154 246 / 20%);
}
.navbar-toggler {
  background-color: #2c64fe;
}

.navbar-toggler .navbar-toggler-icon {
  height: 2px;
  background-color: #fff;
  border-radius: 10px;
  margin: 6px 0;
  display: block;
}
.headerbox a,
.headerbox a:hover {
  color: #000;
}
.navigation .navbar-nav {
  align-items: center;
}
.navigation .navbar-nav .nav-item .nav-link {
  font-size: 14px;
  font-weight: 400;
  padding: 6px 15px;
}
.navigation .navbar-nav .nav-item.login .nav-link {
  background-color: #2c64fe;
  border-radius: 40px;
  padding: 10px 33px;
  color: #fff;
}
.navigation .navbar-nav .dropdown-menu {
  min-width: 100%;
  background: #fff;
  box-shadow: 0px 1.5rem 3.5rem rgb(22 41 68 / 25%);
  border-radius: 6px;
  transition: all 0.3s ease-out;
  padding: 10px;
  margin: 0rem;
  z-index: 999;
}

.navigation .navbar-nav .dropdown-menu h6 {
  font-size: 15px;
  font-weight: 500;
  color: #03e4fe;
  padding: 8px 0;
}
.navigation .navbar-nav .dropdown-menu a {
  color: #000;
  font-weight: 400;
  font-size: 14px;
}
.navigation .navbar-nav .dropdown-menu a:hover {
  background-color: transparent;
}
.banner_sec {
  background-repeat: no-repeat;
  background-position: top right;

  padding: 8rem 40px 40px 40px;
  position: relative;
  background-color: #ddf4fa;
}
.banner_bg1 {
  background-image: url("../../assets/images/graphic.png");
}
.bannertext {
  display: flex;
  flex-flow: column;
  height: 100%;
  justify-content: center;
}
.bannertext h1 {
  font-size: 62px;
  font-weight: 200;
  color: #000;
}
.bannertext h1 span {
  font-size: 45px;
  font-weight: 500;
  color: #2a62fe;
  display: block;
}
.bannertext p {
  font-size: 26px;
  line-height: 1.6;
  color: #000;
  font-weight: 400;
  margin: 15px 0 15px 0;
}
.bannertext .btns a {
  display: inline-block;
  margin-right: 20px;
}
.mobile {
  text-align: center;
}
.diabetescare {
  padding: 40px 0 40px 0;
}

.titleinner {
  text-align: center;
  padding: 0 0 30px 0;
}
.titleinner h2 {
  font-size: 36px;
  font-weight: 500;
  color: #000;
  padding: 0 0 10px 0;
}
.titleinner h2 span {
  color: #03e4fe;
}
.titleinner p {
  color: rgba(0, 0, 0, 0.7);
  font-size: 16px;
  font-weight: 400;
}
.titleinner p span {
  color: #2c64fe;
  font-weight: 500;
}
.carebox {
  background: #ffffff;
  box-shadow: 0px 0rem 1rem rgb(106 132 151 / 15%);
  border: 1px solid #ffffff;
  border-radius: 0.3rem;
  transition: all 0.5s ease-out;
  line-height: 1.6;
  text-align: center;
  padding: 15px;
  margin-bottom: 10px;
}
.morebtns {
  padding: 25px 0 0 0;
  text-align: center;
}
.morebtns a {
  background-color: #2c64fe;
  border-radius: 40px;
  display: inline-flex;
  padding: 12px 33px;
  color: #fff;
}

.carebox:hover {
  border-color: #1e7ef8;
}

.boxbg {
  background-image: url(../images/about-bg-shape1.svg);
  background-repeat: no-repeat;
  background-position: bottom center;
  background-size: 100%;
}
.boxbgtwo {
  background-image: url(../images/about-bg-shape02.svg);
  background-repeat: no-repeat;
  background-position: top center;
  background-size: 100%;
}

.carebox .iconbox {
  width: 130px;
  height: 130px;
  border-radius: 50%;
  background-color: #e7fcff;
  margin: 0 auto;
  padding: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.carebox h3 {
  font-size: 22px;
  font-weight: 500;
  color: #000;
  line-height: 1.6;
  margin-top: 15px;
  margin-bottom: 15px;
}
.carebox p {
  color: rgba(0, 0, 0, 0.7);
  font-size: 16px;
  font-weight: 400;
}

.outcometitle {
  display: flex;
  height: 100%;
  justify-content: center;
  font-size: 30px;
  align-items: center;
  color: #000;
  line-height: 1.6;
}
.outcometitle span {
  font-weight: 500;
  display: block;
}

.clientspeaks .owl-carousel .carebox {
  text-align: left;
}
.clientspeaks .owl-carousel .carebox h3 {
  color: #2c64fe;
  font-size: 24px;
}
.clientspeaks .owl-carousel .carebox p {
  padding: 0 30px;
  position: relative;
}
.clientspeaks .owl-carousel .carebox p img {
  width: auto;
}
.clientspeaks .owl-carousel .carebox p img.topleft {
  position: absolute;
  left: 0;
  top: 0;
}
.clientspeaks .owl-carousel .carebox p img.bottomright {
  position: absolute;
  right: 0;
  bottom: 0;
}
.clientname {
  display: flex;
  width: 100%;
  flex-flow: nowrap;
  align-items: center;
  margin-bottom: 15px;
}
.clientname .profilepic {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: #d9f9ff;
  justify-content: center;
  align-items: center;
  padding: 10px;
}
.clientname span {
  font-size: 14px;
  font-weight: 500;
  color: #000;
  display: inline-block;
  padding-left: 15px;
}

.owl-dots {
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  padding: 10px 0 0 0;
}
.owl-dots .owl-dot {
  background-color: #c4c4c4;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  margin: 0 6px;
}

.owl-dots .owl-dot.active {
  background-color: #2c64fe;
}

.ourcompany {
  background-color: #f4fcfd;
  padding: 10px 0;
  margin-top: 40px;
}

.ourcompanylogos {
  display: flex;
  justify-content: space-between;
}

.ourcompanylogos li {
  padding: 10px;
}

.learnmoreabout ul {
  display: flex;
  justify-content: center;
  align-items: center;
}
.learnmoreabout ul li {
  padding: 0 10px;
}
.learnmoreabout ul li a {
  background-color: #03e4fe;
  color: #fff;
  padding: 10px 30px;
  border-radius: 30px;
  display: inline-flex;
  font-size: 18px;
  font-weight: 400;
}

.learnmoreabout ul li a.colorone {
  background-color: #2c64fe;
}
.learnmoreabout ul li a.colortwo {
  background-color: #ff9d07;
}
.learnmoreabout ul li a.colorthree {
  background-color: #9676d6;
}

footer {
  background-color: #2c64fe;
  padding: 40px 0 0 0;
}

.copyright {
  border-top: 1px solid #fff;
  padding: 15px 0;
  font-size: 14px;
  font-weight: 400;
  text-align: center;
  margin-top: 30px;
  color: #fff;
}

.box01 h4 {
  font-size: 18px;
  font-weight: 500;
  color: #fff;
  padding: 10px 0 15px 0;
}
.box01 ul li {
  padding: 0 0 10px 0;
}
.box01 ul li a {
  color: #fff;
  font-size: 14px;
  font-weight: 400;
}

.fixed-header {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
}

footer .box01 .btns a {
  margin-right: 5px;
}
